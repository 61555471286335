<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <span>
            {{ customerData.Name }}
          </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.LegalAddress"
              class="company-details__input"
              placeholder="Юридический адрес"
            />
          </div>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">Оплату счета №</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="invoiceNumber"
            class="company-details__input"
            placeholder="№ выставленного счёта"
          />
        </div>
        <span class="statement-text">от</span>
        <div class="company-details__date_wrap">
          <BaseInputDate v-model="invoiceDate" class="company-details__date"/>
        </div>
        <span class="statement-text">в сумме</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="amount"
            class="company-details__input"
            placeholder="Сумма прописью"
          />
        </div>
        <span class="statement-text"> рублей гарантируем до </span>
        <div class="company-details__date_wrap">
          <BaseInputDate v-model="paymentDate" class="company-details__date"/>
        </div>
      </div>
      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="userFormData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность руководителя"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="userFormData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО руководителя"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapState } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementTitle: 'Гарантийное письмо',
      invoiceDate: ShowCurrentDate(),
      paymentDate: ShowCurrentDate(),
      amount: '',
      invoiceNumber: '',
      userFormData: {}
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    ServicesDescription
  },

  mounted() {
    this.userFormData = { ...this.currentData };
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData,
      serviceList: (state) => state.statements.module.serviceList
    }),

    currentData() {
      return {
        ContractNumber: this.customerData.ContractNumber,
        ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
        ChiefPosition: this.customerData.ChiefPosition,
        ChiefFullName: this.customerData.ChiefFullName,
        LegalAddress: this.customerData.LegalAddress
      };
    }
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      return {
        CurrentCustomer: {
          ContractNumber: this.userFormData.ContractNumber,
          ContractDate: `${this.userFormData.ContractDate}T00:00:00`,
          ChiefPosition: this.userFormData.ChiefPosition,
          ChiefFullName: this.userFormData.ChiefFullName,
          LegalAddress: this.userFormData.LegalAddress
        },
        Invoice: {
          Number: this.invoiceNumber,
          Date: this.invoiceDate,
          Total: this.amount,
          PaymentDate: this.paymentDate
        }
      };
    },

    getDocument() {
      this.createDocument(this.createModelToSend(), this.createModelToSend());
    }
  }
};
</script>

<style></style>
